import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SecondaryHeader from "../components/SecondaryHeader"

const OrderTrackingPage = () => {
  return (
    <Layout>
      <Seo title={`Track your order`} />
      <SecondaryHeader title={`Track your order`} />

      <div
        id="order-tracking-page"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <form method="GET" action="/track">
          <h1 className="text-center mb-4 font-semibold">Order Tracking</h1>
          <div className="relative block mb-4">
            <input
              className="placeholder:tw-color-primary block bg-white w-full border border-slate-300 rounded-md p-3 shadow-sm focus:outline-none focus:ring-1 sm:text-sm"
              placeholder="Type your order tracking number here..."
              type="text"
              required
              name="order_id"
            />
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn"
              style={{ backgroundColor: "#001a1c" }}
            >
              Track
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default OrderTrackingPage
